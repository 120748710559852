<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Editor from '@tinymce/tinymce-vue'

//import router from "@/router/index.js";

//import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Évènement",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Editor },
  data() {
    return {
      title: "Liste des évènements",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Évènement",
          href: "/",
        },
        {
          text: "Liste des évènements",
          active: true,
        },
      ],
      moyensTransport: ["MOTO", "VOITURE", "TAXI", "BUS"],
      typesDistance: ["COURT", "MOYEN", "LONG"],
      experience: ["R-Débutant", "R-Qualifié", "R-Ambassadeur"],

      codeParrainageFormModif: {
        id: 0,
        code: "",
        user: "",
        etat: "",
        nombrefilleul: 0,
      },
      parrainRow: null,
      totalRows: 1,
      currentPage: 1,
      currentPageActif: 1,
      currentPagePasse: 1,
      perPage: 100,
      perPagePasse: 100,
      perPageActif: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterActif: null,
      filterPast: null,
      codeevent: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "utilisateur", sortable: true, label: "Utilisateur " },
        { key: "telephone", sortable: true, label: "Téléphone " },

        { key: "nomEvent", sortable: true, label: "Nom de l'évènement" },
        { key: "dateDebutFormated", sortable: true, label: "Début " },

        { key: "dateFinFormated", sortable: true, label: "Fin" },
        { key: "adresseEvent", sortable: true, label: "Adresse" },
        { key: "dateCreation", sortable: true, label: "Date création" },
        // { key: "longitude", sortable: true, label: "Longitude" },
        // { key: "latitude", sortable: true, label: "Latitude" },
        { key: "descriptionLong", sortable: true, label: "Description" },
        { key: "urlEvent", display: false },
        { key: "passfield", label: "Pass" },
        { key: "action" },
      ],
      eventForm: {
        dateDebut: "",
        heureDebut: "",
        dateFin: "",
        heureFin: "",
        adresseEvent: "",
        urlEvent: "",
        nomEvent: "",
        longitude: 0.0,
        latitude: 0.0,
        descriptionLong: null,
      },
      eventFormEdit: {
        dateDebut: "",
        heureDebut: "",
        dateFin: "",
        heureFin: "",
        adresseEvent: "",
        urlEvent: "",
        nomEvent: "",
        longitude: 0.0,
        latitude: 0.0,
        descriptionLong: null,
      },

      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showActive: false,
      erreur: false,
      showRecharge: false,
      headerBgVariant: "warning",
      headerTextVariant: "info",
      bodyBgVariant: "light",
      showpopGainParrain: false,
      showpopGainMembre: false,
      gain_parrain_membre: {
        parrain: 0,
        membre: 0,
      },
      new_parrain: null,
      new_gain: null,
      startValue: 1,
      endValue: 500,
      parbon: 500,
      totalValueActif: 0,
      totalValuePasse: 0,
      userChargeLoader: false,
      newDataEvenement: [],
      recherche: "",
      eventInactifListe: [],
      eventActifListe: [],
      eventPasseListe: [],
      eventActifListeLength: 0,
      currentEventId: null,
      showEditEvent: false,
      loadAttente: false,
      loadActif: false,
      loadPasse: false,
      affectModal: false,
      userId: 0,
      eventId:0,
      userFound: [],
      selectedUser:[],
      passActif:[],
      formData:[],


    };
  },
  validations: {},

  mounted() {
    this.evenementActifList();
    this.evenementInactifList();
    this.evenementPasseList();

    setTimeout(() => {

      this.loadAttente = false
      this.loadActif = false
      this.loadPasse = false




    }, 3000);

  },
  methods: {
    selectTarget(val) {
      console.log(val)
      if (this.selectedUser[0]== val.id) {
        this.selectedUser = []
      } else {
        this.selectedUser = []
        this.selectedUser.push(val.id) 
      }
    },
    isSelected(userId) {
    return this.selectedUser.includes(userId);
  },
    async affectUser(){
      const req = await apiRequest('PUT','evenement/affectation',{user_id:this.selectedUser[0], event_id:this.eventId})
      if (req && req.status == 200){
        this.$toast.success('Utilisateur affecté avec succès')
        this.evenementInactifList()
        this.affectModal =false
      }else {
        this.$toast.error('Un problème est survenu')
        this.affectModal =false

      }
    },
    async evenementInactifList() {
      this.loadAttente = true
      const listEvent = await apiRequest(
        "GET",
        "evenement/inactive",
        undefined,
        false
      );

      if (listEvent && listEvent.data) {
        const eventFormatTable = listEvent.data.map(
          (event) => {
            var passData = [];

            event.passList.map((pass) => {



              passData.push({
                passId: pass.id,
                eventId: pass.eventId,
                eventName: event.eventName,
                passName: pass.pass,
                amount: pass.montant,
                days: pass.nombreJours,
                passType: pass.type
              })
            })




            return {
              id: event.id,
              userId: event.user.id,
              dateDebut: event.dateDebut,
              dateFin: event.dateFin,
              adresseEvent:
                event.adresseEvent,
              dateCreation: new Date(event.dateCreation).toLocaleString("fr-FR"),
              dateDebutFormated: new Date(event.dateDebut).toLocaleString("fr-FR"),
              dateFinFormated: new Date(event.dateFin).toLocaleString("fr-FR"),
              pass: passData,
              nomEvent: event.nomEvent,
              urlEvent: event.urlEvent,
              longitude: event.longitude,
              latitude: event.latitude,
              descriptionLong: event.descriptionLong,
              visible: event.visible,
              utilisateur: `${event.user.personne.firstname} ${event.user.personne.lastname}  `,
              telephone: `${event.user.personne.indicatifTel}` + `${event.user.personne.tel}`,

            };
          }
        );
        this.eventInactifListe = eventFormatTable;
        this.loadAttente = false
        this.totalValueInactif = this.eventInactifListe.length;
      }
    },


    async evenementActifList() {
      this.loadActif = true
      const listEventActif = await apiRequest(
        "GET",
        "evenement/active-incoming",
        undefined,
        false
      );

      if (listEventActif && listEventActif.data) {

        const eventFormatTableActif = listEventActif.data.map(
          (event) => {

            var passData = [];

            event.passList.map((pass) => {



              passData.push({
                passId: pass.id,
                eventId: pass.eventId,
                eventName: event.eventName,
                passName: pass.pass,
                amount: pass.montant,
                days: pass.nombreJours,
                passType: pass.type
              })
            })


            return {
              id: event.id,
              userId: event.user.id,
              dateDebut: new Date(event.dateDebut).toLocaleString("fr-FR"),
              dateFin: new Date(event.dateFin).toLocaleString("fr-FR"),
              adresseEvent:
                event.adresseEvent,
              dateCreation: new Date(event.dateCreation).toLocaleString("fr-FR"),
              pass: passData,
              urlEvent: event.urlEvent,
              nomEvent: event.nomEvent,
              longitude: event.longitude,
              latitude: event.latitude,
              descriptionLong: event.descriptionLong,
              visible: event.visible,
              dateDebutFormated: new Date(event.dateDebut).toLocaleString("fr-FR"),
              dateFinFormated: new Date(event.dateFin).toLocaleString("fr-FR"),
              utilisateur: `${event.user.personne.firstname} ${event.user.personne.lastname}  `,
              telephone: `${event.user.personne.indicatifTel}` + `${event.user.personne.tel}`,
            };
          }
        );
        this.eventActifListe = eventFormatTableActif;

        this.totalValueActif = this.eventActifListe.length;
      }
    },

    async evenementPasseList() {
      this.loadPasse = true
      const listEventPasse = await apiRequest(
        "GET",
        "evenement/past-dash",
        undefined,
        false
      );



      if (listEventPasse && listEventPasse.data) {
        const eventFormatTablePasse = listEventPasse.data.map(
          (event) => {
            var passData = [];

            event.passList.map((pass) => {



              passData.push({
                passId: pass.id,
                eventId: pass.eventId,
                eventName: event.eventName,
                passName: pass.pass,
                amount: pass.montant,
                days: pass.nombreJours,
                passType: pass.type
              })
            })
            return {
              id: event.id,
              userId: event.user.id,
              dateDebut: new Date(event.dateDebut).toLocaleString("fr-FR"),
              dateFin: new Date(event.dateFin).toLocaleString("fr-FR"),
              adresseEvent:
                event.adresseEvent,
              dateCreation: new Date(event.dateCreation).toLocaleString("fr-FR"),
              pass: passData,
              nomEvent: event.nomEvent,
              urlEvent: event.urlEvent,

              longitude: event.longitude,
              latitude: event.latitude,
              descriptionLong: event.descriptionLong,
              visible: event.visible,
              dateDebutFormated: new Date(event.dateDebut).toLocaleString("fr-FR"),
              dateFinFormated: new Date(event.dateFin).toLocaleString("fr-FR"),
              utilisateur: event.user ? `${event.user.personne.firstname} ${event.user.personne.lastname}` : "",
              telephone: `${event.user.personne.indicatifTel}` + `${event.user.personne.tel}`,

            };
          }
        );
        this.eventPasseListe = eventFormatTablePasse;
        this.totalValuePasse = this.eventPasseListe.length;
      }
    },

    emptyEditRow() {
      this.eventForm = {
        dateDebut: "",
        heureDebut: "",
        dateFin: "",
        heureFin: "",
        adresseEvent: "",
        urlEvent: "",
        nomEvent: "",
        longitude: 0.0,
        latitude: 0.0,
        descriptionLong: null,
      }
    },
   formatDate(dateStr) {
  // Check if the date string is in the format "YYYY-MM-DD HH:mm:ss"
  const isISO8601Format = dateStr.includes('T');
  if (isISO8601Format) {
    const date = new Date(dateStr);
    console.log("m", `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`)
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  } else {
    const [day, month, year] = dateStr.split('/');
    console.log(`${year}-${month}-${day}`)
    return `${year}-${month}-${day}`;
  }
},

 getTime(dateStr) {
  // Check if the date string is in the format "YYYY-MM-DD HH:mm:ss"
  const isISO8601Format = dateStr.includes('T');
  if (isISO8601Format) {
    const date = new Date(dateStr);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  } else {
    const [, , , hours, minutes] = dateStr.split(/[ :]/);
    return `${hours}:${minutes}`;
  }
},

    editRow(row) {

      console.log('row',row);
      this.currentEventId = row.id;

      this.eventForm.id = row.id
      this.eventForm.dateDebut = this.formatDate(row.dateDebut.split(' ')[0]);
      this.eventForm.heureDebut = row.dateDebut.includes('T') ? this.getTime(row.dateDebut): (row.dateDebut.split(' ')[1]).slice(0, 5);


      this.eventForm.nomEvent = row.nomEvent;
      this.eventForm.dateFin = this.formatDate(row.dateFin.split(' ')[0]);
      this.eventForm.heureFin =  row.dateFin.includes('T') ? this.getTime(row.dateFin): (row.dateFin.split(' ')[1]).slice(0, 5);
      this.eventForm.adresseEvent = row.adresseEvent
      this.eventForm.descriptionLong = row.descriptionLong
      this.eventForm.longitude = row.longitude
      this.eventForm.latitude = row.latitude
      this.eventForm.urlEvent = row.urlEvent
      //console.log("eventForm", this.eventForm);
      this.showEditEvent = true;

    },

    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');
    },


    goToPass(eventId) {
      //router.push(`/event/details/${eventId}`);
      window.open(`/event/all-pass/${eventId}`, '_blank', 'noreferrer');
    },


    onFilteredInactif(filteredItems) {
      this.totalValueInactif = filteredItems.length;
      this.currentPage = 1;
    },




    deactivateRow(row) {
      this.showdisable = true;
      this.currentEventId = row.id;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.currentEventId = row.id;
    },
    activeRow(row) {
      this.showActive = true;
      this.currentEventId = row.id;
      this.passActifList(this.currentEventId)
    },
    closeModal() {
      this.showDelete = false;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },
    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },
    async onUpdateEvent() {



      const updateEvent = await apiRequest(
        "PUT",
        "evenement/update/" + this.currentEventId,
        {
          dateDebut: `${this.eventForm.dateDebut}T${this.eventForm.heureDebut}:00.000`,
          dateFin: `${this.eventForm.dateFin}T${this.eventForm.heureFin}:00.000`,

          adresseEvent: this.eventForm.adresseEvent,
          nomEvent: this.eventForm.nomEvent,
          longitude: this.eventForm.longitude,
          latitude: this.eventForm.latitude,
          descriptionLong: this.eventForm.descriptionLong,
          urlEvent: this.eventForm.urlEvent,

        },
        false
      );

      if (updateEvent && updateEvent.data) {
        this.evenementActifList();
        this.evenementInactifList();
        this.showEditEvent = false;

        this.$toast.success("Information  modifiée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });


        this.emptyEditRow()
      }
    },

    async activeevent() {
      this.showActive = false;
      console.log("this.formData,", this.formData,)
      const update = await apiRequest(
        "POST",
        "evenement/action",
        { id: this.currentEventId, pass_com: this.formData, visible: true },
        false
      );

      if (update.status==200) {

        this.$toast.success("Évènement validé avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

        this.evenementInactifList();
        this.evenementActifList()

      }else{

        this.$toast.error("Échec")

      }
    },

    async deleteEvent() {
      this.showDelete = false;
      const update = await apiRequest(
        "POST",
        "evenement/delete",
        { id: this.currentEventId, visible: false },
        false
      );

      if (update) {


        this.$toast.success("Information supprimée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.evenementInactifList()
        this.evenementActifList();
        this.evenementPasseList();
        this.showDelete = false;

      }
    },

    async deleteevent() {
      this.showdisable = false;
      const update = await apiRequest(
        "POST",
        "evenement/action",
        { id: this.currentEventId, visible: false },
        false
      );

      if (update) {


        this.$toast.success("Information désactivée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.evenementInactifList()
        this.evenementActifList();

      }
    },
    rechargeRow(row) {
      this.showRecharge = true;
      this.parrainRow = row;
    },
    async recharge() {
      this.showRecharge = false;
      const charge = await apiRequest(
        "POST",
        "parrain/recharge",
        { idUser: this.parrainRow.userId },
        false
      );
      if (charge && charge.data) {
        this.$toast.success("Recharge effectuée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();
      }
    },
    changeGaingMembre() {
      this.showpopGainMembre = true;
      this.new_gain = this.gain_parrain_membre.membre;
    },
    changeGainParrain() {
      this.showpopGainParrain = true;
      this.new_parrain = this.gain_parrain_membre.parrain;
    },
    async userSearch() {
      //this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche",

        { mots: this.recherche },
        false
      );
      if (dataSearch && dataSearch.data) {
        this.paginatedDate = [];
        console.log(dataSearch.data);
        const formattedTable = dataSearch.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          return {
            id: user.id,
            username: nom + " " + prenom,
            firstname: user.firstname != null ? user.firstname : "",
            lastname: user.lastname != null ? user.lastname : "",
            pays: user.pays != null ? user.pays : "",
            tel: user.tel && user.tel != "" ? user.indicatifTel + user.tel : "",
            email: user.email != null ? user.email : "",
            //trajet: user.nombreTrajet != null ? user.nombreTrajet : "",
            dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
            badge: user.userValidated != null ? user.userValidated : "",
            //type: type,
            statut: user.active ? "Actif" : "Suspendu",
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.userFound = formattedTable;
        console.log(this.userFound);
        this.rowsdataUser = formattedTable.length;
        this.totalRows = formattedTable.length;

        this.userChargeLoader = false;
      }
    },

    setEventAdress: function (place) {

      if (place) {
        this.eventForm.adresseEvent = place.formatted_address;
        this.eventForm.longitude = place.geometry.location.lng();
        this.eventForm.latitude = place.geometry.location.lat();
      }
    },

    handleAffectUser(eventId) {
      this.eventId = eventId;
      this.affectModal = true;
    },

    async passActifList(id) {

      
      


      //var event =44;
      const data = await apiRequest(
        "GET",
        `evenement/all-pass/?eventId=${id}`,
        undefined,
        false
      );

      //console.log("pass Id",this.eventId);



      if (data && data.data) {


        const eventFormatTableActif = data.data.map((onePass) => {


          return {
            id: onePass.id,
            passName: onePass.pass,
            dateLimite: new Date(onePass.dateLimite).toLocaleString("fr-FR"),
            stock: onePass.stock,
            remaining: onePass.quantiteDispo,
            type: onePass.type,
            days: onePass.nombreJours,
            amount: onePass.montant,
            description: onePass.description || "pas de description",

          }
        });

        this.passActif = eventFormatTableActif;

        this.formData = this.passActif.map(pass => ({ id: pass.id, commission:pass.commission }));


        this.totalValueActif = this.passActif.length;
      }
    },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">


        <div class="card">
          <div class="containerDot" v-if="loadAttente == true || loadActif == true || loadPasse == true">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>

          <div class="card-body">
            <div class="row d-flex justify-content-between p-2">
              <div class="card-title p-3">
                <!-- <a class="btn btn-success p-3"> Ajouter event </a> -->
              </div>

            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">En attente</a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm"
                            :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="newDataEvenement.length != 0
      ? newDataEvenement
      : eventInactifListe
      " :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                      @filtered="onFilteredInactif">
                      <template v-slot:cell(etat)="row">
                        <span v-if="row.item.etat == true" style="
                            padding: 0.25rem 1.2rem;
                            background-color: #4caf50;
                            border-radius: 1.3rem;
                            width: 100% !important;
                            height: 14px;
                            font-size: 13px;
                            color: #fff;
                          ">
                          Actif
                        </span>
                        <span v-if="row.item.etat == false" style="
                            padding: 0.25rem 0.89rem;
                            background-color: #ff5252;
                            border-radius: 1.3rem;
                            width: 100%;
                            height: 16px;
                            font-size: 13px;
                            color: #fff;
                          ">
                          Inactif
                        </span>
                      </template>

                      <template v-slot:cell(utilisateur)="row">

                        <div @click="goToUserDetail(row.item.userId)">
                          <span>
                            {{ row.item.utilisateur }}
                          </span>
                          <i class="fas fa-eye"></i>

                        </div>


                      </template>
                      <template v-slot:cell(passfield)="row">
                        <div @click="goToPass(row.item.id)">
                          <i class="fas fa-eye"></i>

                        </div>
                      </template>
                      <template v-slot:cell(action)="row">

                        <div class="text-nowrap">

                          <a v-if="row.item.visible == true" href="javascript:void(0);" class="text-danger"
                            @click="deactivateRow(row.item)" v-b-tooltip.hover title="désactiver">
                            <i class="mdi mdi-close font-size-18"></i>
                          </a>
                          <a href="javascript:void(0);" class="text-success" @click="activeRow(row.item)"
                            v-b-tooltip.hover v-if="row.item.visible == false" title="activer">
                            <i class="fas fa-check font-size-15"></i>
                          </a>
                          <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                            @click="editRow(row.item)" title="Editer">
                            <i class="mdi mdi-pencil font-size-18"></i>
                          </a>
                          <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                            @click="deleteRow(row.item)" title="Supprimer">
                            <i class="mdi mdi-delete font-size-18"></i>
                          </a>
                          <a href="javascript:void(0);" class="text-info" v-b-tooltip.hover
                            @click="handleAffectUser(row.item.id)" title="Affecter">
                            <i class="mdi mdi-link font-size-18"></i>
                          </a>
                        </div>



                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">

                          <b-pagination v-model="currentPage" :total-rows="totalValueInactif"
                            :per-page="perPage"></b-pagination>
                        </ul>
                      </div>

                    </div>
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">En cours</a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm"
                            :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filterActif" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="eventActifListe" :fields="fields" responsive="sm"
                      :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                      :filter="filter" :filter-included-fields="filterOn">

                      <template v-slot:cell(utilisateur)="row">

                        <div @click="goToUserDetail(row.item.userId)">
                          <span>
                            {{ row.item.utilisateur }}
                          </span>
                          <i class="fas fa-eye"></i>

                        </div>


                      </template>
                      <template v-slot:cell(descriptionLong)="row">

                        <div  style="max-height: 200px;max-width: 200px; overflow: scroll">
                         
                            {{ row.item.descriptionLong }}
                         
                          

                        </div>


                      </template>

                      <template v-slot:cell(action)="row">
                        <div class="text-nowrap">

                          <a v-if="row.item.visible == true" href="javascript:void(0);" class="text-danger"
                            @click="deactivateRow(row.item)" v-b-tooltip.hover title="désactiver">
                            <i class="mdi mdi-close font-size-18"></i>
                          </a>

                          <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                            @click="editRow(row.item)" title="Modifier">
                            <i class="mdi mdi-pencil font-size-18"></i>
                          </a>


                          <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                            @click="deleteRow(row.item)" title="Editer">
                            <i class="mdi mdi-delete font-size-18"></i>
                          </a>

                          <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Annuler">
                            <i class="mdi mdi-cancel font-size-18"></i>
                          </a>

                        </div>


                      </template>
                      <template v-slot:cell(passfield)="row">
                        <div style="text-wrap: nowrap" @click="goToPass(row.item.id)">
                          <i class="fas fa-eye"></i>

                        </div>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">

                          <b-pagination v-model="currentPageActif" :total-rows="totalValueActif"
                            :per-page="perPageActif"></b-pagination>
                        </ul>
                      </div>

                    </div>
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Passés</a>
                  </template>

                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select v-model="perPage" size="sm"
                            :options="pageOptions"></b-form-select>&nbsp;entrées
                        </label>
                      </div>
                    </div>

                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filterPast" />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table class="table-centered" :items="eventPasseListe
      " :fields="fields" responsive="sm" :per-page="perPagePasse" :current-page="currentPagePasse"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                      :filter-included-fields="filterOn">

                      <template v-slot:cell(utilisateur)="row">

                        <div @click="goToUserDetail(row.item.userId)">
                          <span>
                            {{ row.item.utilisateur }}
                          </span>
                          <i class="fas fa-eye"></i>

                        </div>


                      </template>

                      <template v-slot:cell(action)="row">
                        <div class="text-nowrap">

                          <a v-if="row.item.visible == true" href="javascript:void(0);" class="text-danger"
                            @click="deactivateRow(row.item)" v-b-tooltip.hover title="désactiver">
                            <i class="mdi mdi-close font-size-18"></i>
                          </a>

                          <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover
                            @click="editRow(row.item)" title="Editer">
                            <i class="mdi mdi-pencil font-size-18"></i>
                          </a>

                          <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                            @click="deleteRow(row.item)" title="Editer">
                            <i class="mdi mdi-delete font-size-18"></i>
                          </a>
                          <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Annuler">
                            <i class="mdi mdi-cancel font-size-18"></i>
                          </a>

                        </div>

                      </template>
                      <template v-slot:cell(passfield)="row">
                        <div style="text-wrap: nowrap" @click="goToPass(row.item.userId)">
                          <i class="fas fa-eye"></i>

                        </div>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">

                          <b-pagination v-model="currentPagePasse" :total-rows="totalValuePasse"
                            :per-page="perPagePasse"></b-pagination>
                        </ul>
                      </div>

                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-backdrop" centered v-model="erreur" title="Veuillez remplir toutes les options."
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false">Ok</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showdisable"
      title="Voulez-vous vraiment désactiver ce code de parrainage ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deleteevent">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showDelete" title="Voulez-vous vraiment supprimer cet évènement?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deleteEvent">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered size="md" v-model="showActive" title="Validation de l'évènement"
      title-class="font-18" hide-footer>

      <div class="card-body">

        <div class=" mx-auto d-flex justify-content-between row mb-3">
          <div class="" style="font-size:larger; font-weight:bold">
            Pass 
          </div>
          <div>
            Commission
          </div>
        </div>
        <div v-for="(item , index) in passActif" :key="index" class=" mx-auto d-flex justify-content-between row mb-2">
          <div class="" style="font-size:large; font-weight:bold">
            {{item.passName}}
          </div>
          <div>
           <input type="number" class="form-control" placeholder="0f"  v-model="formData[index].commission"/>
          </div>
        </div>

      </div>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showActive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activeevent()">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-center" centered size="xl" v-model="showEditEvent" title="Editer évènement" title-class="font-18"
      hide-footer>

      <form class="form-horizontal" role="form" method="post" @submit.prevent="ModifSubmit">
        <div class="row">
          <div class="col-md-6 mb-3">

            <label for="dateDebut">Date début</label>
            <input type="date" class="form-control py-4" id="dateDebut" v-model="eventForm.dateDebut" />
          </div>
          <div class="col-md-6 mb-3">

            <label for="dateDebut">Heure début</label>
            <input type="type" class="form-control py-4" id="dateDebut" v-model="eventForm.heureDebut" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="dateFin">Date fin</label>
            <input type="date" class="form-control py-4" id="dateFin" v-model="eventForm.dateFin"/>
          </div>
          <div class="col-md-6 mb-3">

            <label for="dateDebut">Heure fin</label>
            <input type="type" class="form-control py-4" id="dateDebut" v-model="eventForm.heureFin" />
          </div>

          <div class="col-md-6 mb-3">
            <label for="nomEvent">Nom de l'évènement</label>
            <input type="text" class="form-control py-4" id="nomEvent" v-model="eventForm.nomEvent" />
          </div>
          <div class="col-md-6 mb-12">
            <label for="descriptionLong">Description de l'évènement</label>
            <Editor id="event-edit" api-key="6z7bo0jx43facf9rinxcqklzjsqcjggj14blvingkobdtuz8" :init="{
                                                    toolbar_mode: 'sliding',
                                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }" :initial-value="eventForm.descriptionLongt" v-model="eventForm.descriptionLong"
                            style="height: 500px;"  />
            <!-- <textarea id="descriptionLong" v-model="eventForm.descriptionLong" class="form-control" rows="10"
              placeholder="description"> 
            </textarea>-->
          </div>


          <div class="col-md-6 mb-3">
            <label for="lastname">Adresse de l'évènement</label>
            <gmap-autocomplete :value="eventForm.adresseEvent" type="text" class="form-control" id="depart"
              placeholder="Ville de résidence" @place_changed="setEventAdress" :select-first-on-enter="true"
              style="height: 50px">
            </gmap-autocomplete>
          </div>

          <div class="col-md-6 mb-3">
            <label for="lastname">Url de l'évènement</label>
            
            <textarea type="type" class="form-control py-4" id="urlEvent" v-model="eventForm.urlEvent"
              style="height: 30px">
        </textarea>



          </div>


        </div>
        <button class="btn btn-Back float-right" type="submit" @click="onUpdateEvent()">
          Modifier
        </button>
      </form>
    </b-modal>


    <b-modal id="modal-backdrop" centered v-model="showRecharge"
      title="Voulez-vous vraiment recharger cet utilisateur ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showRecharge = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="recharge">Oui</b-button>
      </div>
    </b-modal>
    <b-modal v-model="showpopGainParrain" title="Modifier le gain des parrains">
      <b-container fluid>
        <b-row class="mb-1">
          <b-col cols="3">Gain</b-col>
          <b-col>
            <b-form-input class="form-controle" v-model="new_parrain">
            </b-form-input>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="primary" size="sm" @click="showpopGainParrain = false">
            Annuler
          </b-button>
          <b-button variant="primary" size="sm" class="ml-2" @click="submitParrain">
            Valider
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="showpopGainMembre" title="Modifier le gain des membres">
      <b-container fluid>
        <b-row class="mb-1 d-flex align-items-center">
          <b-col cols="2" style="font-size: 18px">Gain</b-col>
          <b-col>
            <b-form-input class="form-controle" v-model="new_gain">
            </b-form-input>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="primary" size="sm" @click="showpopGainMembre = false">
            Annuler
          </b-button>
          <b-button variant="primary" size="sm" class="ml-2" @click="submitMembre">
            Valider
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="modal-center" centered size="md" v-model="affectModal" title="Affecter un utilisateur"
      title-class="font-18" hide-footer>

      <form class="form-horizontal" role="form" method="post" @submit.prevent="ModifSubmit">
        <div class="row d-flex align-items-start">
          <div class="col-md-6 mb-3">
            <label for="dateDebut">Rechercher un utilisateur</label>
            <input type="text" class="form-control py-4" id="pass" @input="userSearch" v-model="recherche" />
            <ul class="suggestions mt-2 p-2 " style="width:80%; background-color:#eee; border-radius:4px;">

              <li v-for="(user, index) in userFound" :key="index">

                <div class=" d-block d-flex justify-content-between">
                  <div>{{ user.firstname + " " + user.lastname }}</div>
                  <div><input type="checkbox" class=" d-block " :value="user.id"  :checked="isSelected(user.id)"
                      @change="selectTarget(user)"></div>

                </div>


                <hr class="my-1">
              </li>
              <li class="d-flex justify-content-center">
                ...
                <hr class="my-1">
              </li>
            </ul>
          </div>
          <div class="col-md-6 pt-4 mb-3 d-flex ">
            <button class="btn btn-Back mt-2 float-right" type="submit" @click="affectUser()">
              Affecter
            </button>
          </div>



        </div>

      </form>
    </b-modal>



  </Layout>
</template>
<style>
.alignBtn {
  display: flex;
  float: right;

  align-items: flex-end;
}

.bg-secondary-special {
  background: #3aaa35;
}

.bg-secondary-smile {
  background: #3aaa35;
}

.widget-wrapping {
  display: flex !important;
  padding: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}

.thesize {
  font-size: 2em;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.typeParrain {
  margin-top: 0.444rem;
  font-size: 17px;
  color: #fff;
}

.gain {
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.fa-edit {
  font-size: 17px;
  cursor: pointer;
}

thead {
  background: #3aaa35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;

}


.pac-container {
  z-index: 10000 !important;
}

.btn-Back {
  background: #3aaa35;
  color: #fff;
}


.modal-header {
  background: #3aaa35;

}


.modal-title {
  color: #fff;
}


.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35 !important;
  border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}
</style>
